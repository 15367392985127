/* eslint-disable react/destructuring-assignment, react/prop-types, react/require-optimization */

import React, {useState} from "react";
import {applyMiddleware, createStore} from "redux";
import ErrorBoundary from "../../../shared/components/ErrorBoundary";
import PropTypes from "prop-types";
import {Provider} from "react-redux";
import {initializeStore} from "../../Rocksbox/components/stateInitializer";
import promise from "redux-promise";
import reducer from "../../../reducers";

const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

const CarouselContainer = ({apiToken, customerId, params, renderCouponBanner, children}) => {
  const store = createStoreWithMiddleware(reducer, initializeStore({
    apiToken,
    customerId,
    params,
    renderCouponBanner
  }));

  const [
    fetchedProps,
    setFetchedProps
  ] = useState({
    ...{
      apiToken,
      customerId,
      params,
      renderCouponBanner
    },
    "createdAt": Date.now()
  });

  const fetchNewProps = (path, callback) => {
    jQuery.get(path).done((data) => {
      data.createdAt = Date.now();
      setFetchedProps(data);
      // eslint-disable-next-line callback-return
      if (callback) callback();
    });
  };

  return (
    <ErrorBoundary>
      <Provider store={store}>
        {React.cloneElement(children, {
          ...fetchedProps,
          fetchNewProps
        })}
      </Provider>
    </ErrorBoundary>
  );
};

CarouselContainer.propTypes = {
  "apiToken": PropTypes.string,
  "children": PropTypes.element.isRequired,
  "customerId": PropTypes.string,
  "params": PropTypes.object.isRequired,
  "renderCouponBanner": PropTypes.bool
};

CarouselContainer.defaultProps = {
  "apiToken": null,
  "customerId": null,
  "renderCouponBanner": false
};

export default CarouselContainer;
