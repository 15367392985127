/* eslint-disable react/jsx-no-bind, react/no-multi-comp, react/display-name, no-negated-condition, no-unused-vars, max-len, max-lines */

import React, {useEffect, useState} from "react";
import ContactAPI from "./modules/ContactAPI";
import EventsAPI from "../../../shared/modules/EventsAPI";
import Helper from "../../../shared/modules/Helper";
import PropTypes from "prop-types";
import SuccessfullyAppliedCodeButton from "./SuccessfullyAppliedCodeButton";
import {useCookies} from "react-cookie";

const EmailSmsCapture = ({enable2024Legal}) => {
  const imageUrlPrefix = "https://karat-herokuapp-com.freetls.fastly.net/s3media";

  const [
    isOpen,
    setIsOpen
  ] = useState(false);

  const [
    firstName,
    setFirstName
  ] = useState("");

  const [
    email,
    setEmail
  ] = useState("");

  const [
    phone,
    setPhone
  ] = useState("");

  const [
    contactId,
    setContactId
  ] = useState(null);

  const [
    displayCode,
    setDisplayCode
  ] = useState(false);

  const [
    errorMessage,
    setErrorMessage
  ] = useState("");

  const [
    copyCodeText,
    setCopyCodeText
  ] = useState("Copy Code");

  const [
    cookies,
    setCookie,
    updateCookies
  ] = useCookies([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
      EventsAPI.trackContactEvent(
        "Viewed Email/SMS Capture Modal",
        "Email/SMS Capture Modal Opened"
      ).then(response => setCookie("seen_email_sms_capture", true, {
        "expires": Helper.oneYearFromNow(),
        "path": "/"
      }));
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleEmailChange = (event) => {
    setErrorMessage("");
    setEmail(event.target.value);
  };

  const handleEmailCapture = () => {
    if (!email) {
      setErrorMessage("Please fill out the required fields");
    } else {
      ContactAPI.createContact({
        "contact_type_id": 9,
        email,
        // Contact::EMAIL_SOURCE[:capture_modal] = 5
        "email_source": 5,
        "first_name": firstName
      }).then((response) => {
        if (response.error) {
          setErrorMessage(response.error);
          return;
        }

        updateCookies();
        setContactId(response.contact.id);
      });
    }
  };

  const handlePhoneCapture = () => {
    ContactAPI.subscribeToSmsChannels(
      contactId,
      {phone}
    ).then((response) => {
      if (response.error && response.error.phone) {
        setErrorMessage(response.error.phone[0]);
        return;
      }

      setDisplayCode(true);
    });
  };

  const handleSkip = () => setDisplayCode(true);

  const displayEmailInput = () => (
    <div
      className="mt-8 1024:mt-0 flex flex-col gap-10 1024:gap-6"
      data-testid="email-capture-modal"
    >
      <div>
        <div className="font-serif text-7xl 1024:text-8xl uppercase flex items-start justify-center gap-2 lining-nums">
          15% off
          <div className="text-base 1024:text-3xl">
            *
          </div>
        </div>
        <div className="font-serif-italic text-3xl 1024:text-5xl -mt-1 1024:-mt-3">
          your first purchase
        </div>
      </div>
      <div className="1024:px-5">
        <div className="flex flex-col">
          <input
            className="rounded-sm text-xl"
            onChange={() => setFirstName(event.target.value)}
            placeholder="First Name"
            type="text"
            value={firstName}
          />
          <input
            className={`rounded-sm text-xl ${errorMessage ? "border-2 !border-red-600" : ""}`}
            onChange={handleEmailChange}
            placeholder="Email*"
            type="text"
            value={email}
          />
        </div>
        <div className="flex flex-col gap-4 1024:gap-3">
          <button
            className="button-dark tracking-widest uppercase w-full 1024:max-w-72 mx-auto 1024:mt-1"
            onClick={handleEmailCapture}
            type="button"
          >
            Sign Up
          </button>
          <div className="text-red-600 font-sans-demibold h-[24px]">
            {errorMessage}
          </div>
        </div>
      </div>
      <div className="1024:px-5">
        <div className="text-xs leading-tight 1024:leading-none text-gray-700 rounded-md p-2.5 1024:px-2.5">
          *By submitting this form, you agree to our&nbsp;
          <a
            className="underline"
            href="/privacypolicy"
          >
            Privacy Policy
          </a>

          &nbsp;&&nbsp;

          <a
            className="underline"
            href={enable2024Legal ? "/tos" : "/privacypolicy#tos"}
          >
            Terms of Use
          </a>
          . Online shop only. Offer excludes memberships, Girls Crew, and prior purchases.
          Offer valid for first time purchases only. Redemption limited to one-time user per customer.
          May not be combined with any other offer.
          Must apply promo code SHINE at website checkout to receive 15% off retail price.
        </div>
      </div>
    </div>
  );

  const displayPhoneInput = () => (
    <div className="mt-10 flex flex-col gap-14 1024:gap-6 1024:mt-0">
      <div>
        <div className="font-serif text-4xl 1024:text-6xl">
          Add Text Alerts
        </div>
        <div className="font-sans-demibold text-sm 1024:text-xl uppercase tracking-widest leading-none max-w-44 mx-auto 1024:max-w-full">
          Be the first to know about our best deals & new collections
        </div>
      </div>
      <div className="px-2.5">
        <input
          className={`rounded-sm text-xl ${errorMessage ? "border-2 !border-red-600" : ""}`}
          onChange={() => setPhone(event.target.value)}
          placeholder="Phone Number"
          type="tel"
          value={phone}
        />
        <div className="flex flex-col gap-4">
          <button
            className="button-dark tracking-widest uppercase w-full 1024:max-w-72 mx-auto mobile:w-full"
            onClick={handlePhoneCapture}
            type="button"
          >
            Text Me
          </button>
          <div className="text-red-600 font-sans-demibold h-[24px]">
            {errorMessage}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-xs leading-none text-gray-700 rounded-md p-2.5">
          Submitting this form is my signature to consent to receiving recurring automated transactional,
          promotional, and personalized marketing text messages (e.g. order updates, offers, and cart reminders)
          from Rocksbox at this cell phone number. Consent is not a condition of any purchase.
          Reply STOP to cancel. Msg frequency varies. Msg & data rates may apply. By enrolling you accept our&nbsp;
          <a
            className="underline"
            href={enable2024Legal ? "/tos" : "/privacypolicy#tos"}
          >
            Terms of Use
          </a>

          &nbsp;&&nbsp;

          <a
            className="underline"
            href="/privacypolicy"
          >
            Privacy Policy
          </a>
          .
        </div>
        <button
          className="text-gray-500 text-sm font-sans-bold underline hover:text-gray-700"
          onClick={handleSkip}
          type="button"
        >
          Skip
        </button>
      </div>
    </div>
  );

  const handleCopyCode = () => {
    navigator.clipboard.writeText("SHINE");
    setCopyCodeText("Copied!");
  };

  const displayPromoCode = () => (
    <div className="flex flex-col gap-36 justify-between h-full mt-8 1024:mt-14 1024:gap-14 1024:pr-16 ">
      <div className="flex flex-col gap-4 mobile:gap-0">
        <div className="font-serif text-5xl 1024:text-7xl uppercase">
          Welcome!
        </div>
        <div className="text-2xl 1024:text-4xl uppercase w-full mx-auto leading-none">
          Enjoy Code&nbsp;
          <span className="bold">
            SHINE
          </span>
          {" "}
          for&nbsp;
          <span className="bold">
            15% off*
          </span>
        </div>
        <div className="mt-4 flex flex-col 1024:flex-row items-center justify-center gap-2">
          <SuccessfullyAppliedCodeButton className="button-dark w-full min-w-24" />
          <button
            className="button-outlined-white w-full min-w-24"
            onClick={handleCopyCode}
            type="button"
          >
            {copyCodeText === "Copy Code" && copyCodeText}
            {copyCodeText !== "Copy Code" && <i className="fa-regular fa-check" />}
            {copyCodeText !== "Copy Code" && copyCodeText}
          </button>
        </div>
      </div>

      <div>
        <div className="text-xs leading-tight text-gray-700 rounded-md p-2.5">
          *Online shop only. Offer excludes memberships, Girls Crew, and prior purchases.
          Offer valid for first time purchases only. Redemption limited to one-time user per customer.
          May not be combined with any other offer.
          Must apply promo code SHINE at website checkout to receive 15% off retail price.
        </div>
      </div>
    </div>
  );

  return (
    isOpen && (
      <div className="email-sms-capture modal-container">
        <div
          className="modal-backdrop"
          onClick={() => setIsOpen(false)}
        />
        <div className="modal-body p-2.5 bg-teal-50 m-5 flex flex-col bg-center bg-no-repeat bg-cover bg-email-sms-modal-mobile 1024:bg-email-sms-modal-desktop w-80 max-w-full 1024:w-[1024px] 1024:max-w-[720px] 1024:max-h-[536px] mobile:h-[652px]">
          <div className="content-color flex flex-col justify-between h-full 1024:max-w-[440px] 1024:place-self-end 1024:pb-5">
            <div
              className="cursor-pointer transition-all duration-300 place-self-end size-8 hover:bg-white/90 rounded-full flex items-center justify-center"
              onClick={() => setIsOpen(false)}
            >
              <img
                className="size-6 1024:size-3"
                src={`${imageUrlPrefix}/icons/light/xmark.svg`}
              />
            </div>
            <div className="p-2.5 flex flex-col items-center text-center h-full justify-center">
              <div className="">
                {
                  !contactId && displayEmailInput()
                }
                {
                  contactId && !displayCode && displayPhoneInput()
                }
                {
                  contactId && displayCode && displayPromoCode()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

EmailSmsCapture.propTypes = {"enable2024Legal": PropTypes.bool.isRequired};

export default props => <EmailSmsCapture {...props} />;
