import API from "../../../../shared/modules/API";

export default class ContactAPI {
  static API_TOKEN = null;

  static API_VERSION = 1;

  static createContact (data = {}) {
    return (
      fetch(`/api/v${this.API_VERSION}/contacts`, {
        "body": JSON.stringify(data),
        "credentials": "same-origin",
        "headers": {
          ...API.authHeaders(),
          "Content-Type": "application/json"
        },
        "method": "POST"
      }).
        then(response => response.json())
    );
  }

  static subscribeToSmsChannels (contactId, data = {}) {
    return (
      fetch(`/api/v${this.API_VERSION}/contacts/${contactId}/subscribe_to_sms_channels`, {
        "body": JSON.stringify(data),
        "credentials": "same-origin",
        "headers": {
          ...API.authHeaders(),
          "Content-Type": "application/json"
        },
        "method": "PATCH"
      }).
        then(response => response.json())
    );
  }
}
