import PropTypes from "prop-types";
import React from "react";


const SuccessfullyAppliedCodeButton = ({className}) => (
  <a
    className={className}
    href="/jewelry"
  >
    Start Shopping!
  </a>
);

SuccessfullyAppliedCodeButton.defaultProps = {"className": "button-primary"};

SuccessfullyAppliedCodeButton.propTypes = {"className": PropTypes.string};

export default SuccessfullyAppliedCodeButton;
