/* eslint-disable react/jsx-no-bind, react/no-multi-comp, no-negated-condition, no-unused-vars, max-len, max-lines */

import React, {useState} from "react";
import AnalyticsHelper from "../../../shared/modules/AnalyticsHelper";
import ContactAPI from "./modules/ContactAPI";
import EventsAPI from "../../../shared/modules/EventsAPI";
import Helper from "../../../shared/modules/Helper";
import LoadingSpinner from "../../../bundles/BuyItNow/components/LoadingSpinner";
import PropTypes from "prop-types";
import SuccessfullyAppliedCodeButton from "./SuccessfullyAppliedCodeButton";
import {withCookies} from "react-cookie";

const EmailFooterCapture = ({cookies}) => {
  const [
    copyCodeText,
    setCopyCodeText
  ] = useState("Copy Code");

  const [
    displayCode,
    setDisplayCode
  ] = useState(false);

  const [
    email,
    setEmail
  ] = useState("");

  const [
    errorMessage,
    setErrorMessage
  ] = useState("");

  const [
    firstName,
    setFirstName
  ] = useState("");

  const [
    isLoading,
    setIsLoading
  ] = useState(false);

  const handleCopyCode = () => {
    navigator.clipboard.writeText("SHINE");
    setCopyCodeText("Copied!");
  };

  const createContact = async () => {
    const response = await ContactAPI.createContact({
      "contact_type_id": 9,
      email,
      "email_source": 6,
      "first_name": firstName
    });

    const {contact, error} = response;

    if (error) {
      setErrorMessage(error);
    }

    return contact || null;
  };

  const sendContactEvent = async (contactId) => {
    const response = await EventsAPI.trackContactEvent(
      "signup_from_footer",
      `contact_id: ${contactId}, email: ${email}`,
      cookies.get("anonymous_contact_id")
    );

    const {error} = response;

    if (error) {
      setErrorMessage(error);
    }

    return response || null;
  };

  const handleEmailCapture = async () => {
    setErrorMessage("");
    setIsLoading(true);

    if (!email) {
      setIsLoading(false);
      setErrorMessage("Please fill out the required fields");
      return;
    }

    if (!Helper.validateEmail(email)) {
      setIsLoading(false);
      setErrorMessage("Please enter a valid email address");
      return;
    }

    const contact = await createContact();

    if (errorMessage) {
      setIsLoading(false);
      return;
    }

    AnalyticsHelper.sendFooterSignupEvent(email);

    await sendContactEvent(contact.id);

    if (errorMessage) {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setDisplayCode(true);
  };

  const handleEmailChange = (event) => {
    setErrorMessage("");
    setEmail(event.target.value);
  };

  const renderSignUp = () => (
    <div
      className="footer-signup-form space-y-4"
    >
      <div>
        <div className="font-serif text-6xl flex items-start gap-1">
          15% OFF
          <span className="align-super text-sm">
            &#42;
          </span>
        </div>
        <div className="font-serif-italic text-2xl">
          your first purchase
        </div>
      </div>
      <div className="space-y-4">
        <div>
          <input
            className="rounded-sm text-xl"
            onChange={event => setFirstName(event.target.value)}
            placeholder="First Name"
            type="text"
            value={firstName}
          />
          <input
            className={`rounded-sm text-xl ${errorMessage ? "border-2 !border-rose-600" : ""}`}
            onChange={handleEmailChange}
            placeholder="email@example.com"
            type="text"
            value={email}
          />
          <button
            className="button-white button-lg min-w-24"
            disabled={isLoading}
            onClick={handleEmailCapture}
            type="button"
          >
            {!isLoading && "Sign Up"}
            {isLoading &&
            <LoadingSpinner
              color="#171717"
              size={24}
            />}
          </button>
        </div>
        {errorMessage &&
        <div className="alert-filled alert-danger alert-sm">
          {errorMessage}
        </div>}
      </div>
    </div>
  );

  const renderWelcome = () => (
    <div
      className="footer-signup-welcome space-y-4"
    >
      <div>
        <div className="font-serif text-6xl flex items-start gap-1">
          Welcome!
        </div>
        <div className="font-sans text-2xl uppercase tracking-wide">
          enjoy code&nbsp;
          <span className="font-sans-bold">
            shine
          </span>
          &nbsp;for 15% off
          <span className="align-super text-sm">
            &#42;
          </span>
        </div>
      </div>
      <div className="flex gap-2 items-start p-1">
        <SuccessfullyAppliedCodeButton className="button-primary button-lg min-w-24 w-auto" />
        <button
          className="button-white button-lg min-w-24 w-auto"
          onClick={handleCopyCode}
          type="button"
        >
          {copyCodeText === "Copy Code" && copyCodeText}
          {copyCodeText !== "Copy Code" && <i className="fa-regular fa-check" />}
          {copyCodeText !== "Copy Code" && copyCodeText}
        </button>
      </div>
    </div>
  );

  return (
    <div className="footer-signup 430:max-w-[380px] space-y-5">
      {!displayCode ? renderSignUp() : renderWelcome()}
      <div className="text-xs text-neutral-400">
        &#42;By submitting this form, you agree to our
        {" "}
        <a
          className="text-underline !text-neutral-400"
          href="/privacypolicy"
        >
          Privacy Policy
        </a>
        {" & "}
        <a
          className="text-underline !text-neutral-400"
          href="tos"
        >
          Terms of Use
        </a>
        . Online shop only. Offer excludes memberships, Girls Crew and prior purchases. Offer valid for first time purchases only. Redemption limited to one-time use per customer. May not be combined with any other offer. Must apply promo code&nbsp;
        {displayCode && "SHINE "}
        at website checkout to receive 15% off retail price.
      </div>
    </div>
  );
};

EmailFooterCapture.propTypes = {"cookies": PropTypes.object.isRequired};

export default withCookies(EmailFooterCapture);
